import { login, logout, getUserInfo, allowLogin } from '@/api/userApi'
import { getToken, removeToken, setToken } from '@/utils/auth'
import { MessageToast } from '@/utils/message'
import _ from 'lodash'

interface UserState {
  token: string
  userInfo?: any
  intoSchoolInfo?: any // 教育局进入到学校内部的学校数据
}

const getDefaultState = () => {
  const initState: UserState = {
    token: getToken() as string,
  }
  return initState
}

const state: UserState = getDefaultState()

const mutations = {
  RESET_STATE: (state: UserState) => {
    state = _.extend(state, getDefaultState())
  },
  SET_TOKEN: (state: UserState, token: string) => {
    state.token = token
    setToken(token)
  },
  SET_USER_INFO: (state: UserState, info: any) => {
    state.userInfo = info
  } 
}

const actions = { 
  // user login
  login({ commit, dispatch }: any, params: { username: string, password: string ,who:string}) {
    return new Promise((resolve: Function, reject: any) => {
      // 登陆的时候移除token
      dispatch('removeToken').then(() => {
        allowLogin({ telephone: params.username }).then((data) =>{ 
          
     
       
          if(data.code <200 && data.code >300){
            return false
          }
          
          login(params).then((data: any) => {
          commit('SET_TOKEN', data.access_token)
          resolve()
        }, reject)}, (error) => {
          // 不允许登陆
          MessageToast.error(error.msg || '非系统用户或已被禁用，请联系管理员。')
          reject()
         
        })
      })
    })
  },

  // get user info
  getInfo({ commit }: any) {
    return new Promise((resolve, reject) => {
      getUserInfo().then((data: any) => {
        const userInfo = data || {}
        // 当前用户信息
        commit('SET_USER_INFO', userInfo)
        // 每次刷新清除sessionStorage即用户搜索内容
        window.sessionStorage.clear()
        resolve(userInfo)
      }, reject)
    })
  },

  // user logout
  logout({ commit }: any) {
    return new Promise((resolve: any, reject) => {
      logout().then(() => {
        removeToken() // must remove token first
        commit('RESET_STATE')
        resolve()
      }, reject)
    })
  },

  // remove token
  removeToken({ commit }: any) {
    return new Promise((resolve: any) => {
      removeToken() // must remove token first
      commit('RESET_STATE')
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
