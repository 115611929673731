import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { trackPush } from '@/utils'

/**
 * 这里存放不需要权限验证的路由
 */
export const constantRoutes: Array<RouteRecordRaw> = [
  { path: '/', name: 'Home', redirect: '/monitor' },
  { path: '/404', name: '404', component: () => import ('@/views/Common/404.vue') },
  { path: '/500', name: '500', component: () => import ('@/views/Common/500.vue') },
  { path: '/await', name: 'Await', component: () => import ('@/views/Common/await.vue') },

  { path: '/login', name: 'Login', component: () => import('@/views/Login/index.vue') }, // 登录
  { path: '/scroll-demo', name: 'ScrollDemo', component: () => import('@/views/ScrollDemo.vue') }, // 登录

  { path: '/monitor', name: 'Monitor', component: () => import('@/views/Monitor/index.vue') }, // 监控首页（联考 or 校考）
  { path: '/school-list', name: 'SchoolList', component: () => import('@/views/Work/SchoolList.vue') }, // 学校列表页
  { path: '/work-list', name: 'WorkList', component: () => import('@/views/Work/WorkList.vue') }, // 作业列表页
  { path: '/work-detail', name: 'WorkDetail', component: () => import('@/views/Work/WorkDetail.vue') }, // 作业详情页
  { path: '/report-details', name: 'ReportDetails', component: () => import('@/views/Work/ReportDetails.vue') }, // 作业报表分析明细页

  { path: '/switch-unit', name: 'SwitchUnit', component: () => import('@/views/Switch/Unit.vue') }, // 切换单位（切换角色 or 切换区域 or 切换学校）
  { path: '/switch-exam', name: 'SwitchExam', component: () => import('@/views/Switch/Exam.vue') }, // 切换考试（校考 or 联考）

  { path: '/examination', name: 'Examination', component: () => import('@/views/Examination/index.vue') }, // 考试详情（校考 or 联考）
  { path: '/examination-report', name: 'ExaminationReport', component: () => import('@/views/Examination/ReportDetails.vue') }, // 考试报表详情（校考 or 联考）

  {
    // 扩展应用主路由
    path: '/applications', component: () => import('@/views/Applications/index.vue'),
    redirect: '/applications/synthesize-score',
    children: [
      { path: 'synthesize-score', name: 'SynthesizeScore', component: () => import('@/views/Applications/SynthesizeScore.vue'), meta: { title: '学生个体分析' }},
      { path: 'individual-analisys', name: 'IndividualAnalisys', component: () => import('@/views/Applications/IndividualAnalisys.vue'), meta: { title: '个体分析' }},
      { path: 'student-qualities', name: 'studentQualities', component: () => import('@/views/Applications/studentQualities.vue'), meta: { title: '学生核心素养' }},
      { path: 'teacher-evaluation', name: 'TeacherEvaluation', component: () => import('@/views/Applications/TeacherEvaluation.vue') },
      { path: 'teacher-config', name: 'TeacherConfig', component: () => import('@/views/Applications/TeacherConfig.vue') },
      { path: 'teacher-simple', name: 'TeacherSimple', component: () => import('@/views/Applications/TeacherSimple.vue') }
    ]
  }
]

/**
 * 这里存放需要进行权限验证的路由
 */
export const asyncRoutes = []

export const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes
})

// SPA支持友盟统计
router.afterEach(function (to, from) {
  if (to.path && window._czc) {
    const location = window.location // 路由变化
    const contentUrl = '/#' + to.fullPath.split('?')[0] // 自定义当前 url，可带上路由以此区分每个页面
    const refererUrl = location.protocol + '//' + location.host + '/#' + from.fullPath.split('?')[0]
    // See https://developer.umeng.com/docs/67963/detail/74518
    trackPush([false], '_setAutoPageview')
    trackPush([contentUrl, refererUrl], '_trackPageview')
  }
})

export default router
