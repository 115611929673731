import _ from 'lodash'
import examApi from '@/api/examApi'
import workApi from '@/api/workApi'
import { ReportTypeEnum } from '@/types/enums' 


type ReportDataType = {
  loading: false,
  data: any
}

interface ExamState {
  examInfo: any
  reportData: { // 监控面板报表数据
    [ReportTypeEnum.FourRate]: ReportDataType // 一分四率
    [ReportTypeEnum.ReachRate]: ReportDataType // 达线率
    [ReportTypeEnum.ContributionRate]: ReportDataType // 贡献率
    [ReportTypeEnum.Top50Student]: ReportDataType // 前N名学生分布接口
    [ReportTypeEnum.AcademicGrade]: ReportDataType // 学业等级分布
  },
  reportDetail: { // 考试详情报表数据
    [ReportTypeEnum.TopNStudent]: ReportDataType // 拔尖学生花名册
    [ReportTypeEnum.SubTopicScore]: ReportDataType // 小题分得分情况
    [ReportTypeEnum.BoxplotDistributed]: ReportDataType // 盒须图分布
    [ReportTypeEnum.ExamBasicScore]: ReportDataType // 考试基本得分信息
    [ReportTypeEnum.TotalScoreRate]: ReportDataType // 考试全部得分率
    [ReportTypeEnum.ScoreSegment]: ReportDataType // 成绩分段表
    [ReportTypeEnum.CriticalDistributed]: ReportDataType // 临界生分布
    [ReportTypeEnum.SubTopicAnalysis]: ReportDataType // 小题分析表
  },
  workReportData:any
}

const getDefaultState = () => {
  const initState: ExamState = {
    examInfo: '',
    reportData: {
      [ReportTypeEnum.FourRate]: { loading: false, data: '' },
      [ReportTypeEnum.ReachRate]: { loading: false, data: '' },
      [ReportTypeEnum.ContributionRate]: { loading: false, data: '' },
      [ReportTypeEnum.Top50Student]: { loading: false, data: '' },
      [ReportTypeEnum.AcademicGrade]: { loading: false, data: '' }
    },
    reportDetail: {
      [ReportTypeEnum.TopNStudent]: { loading: false, data: '' },
      [ReportTypeEnum.SubTopicScore]: { loading: false, data: '' },
      [ReportTypeEnum.BoxplotDistributed]: { loading: false, data: '' }, 
      [ReportTypeEnum.ExamBasicScore]: { loading: false, data: '' },
      [ReportTypeEnum.TotalScoreRate]: { loading: false, data: '' },
      [ReportTypeEnum.ScoreSegment]: { loading: false, data: '' },
      [ReportTypeEnum.CriticalDistributed]: { loading: false, data: '' },
      [ReportTypeEnum.SubTopicAnalysis]: { loading: false, data: '' }
    },
    workReportData:{
        0:{ loading: false, data: '' },
    }
  }
  return initState
}

const state: ExamState = getDefaultState()

const mutations = {
  RESET_STATE: (state: ExamState) => {
    state = _.extend(state, getDefaultState())
  },
  SET_EXAM_INFO: (state: ExamState, info: any) => {
    state.examInfo = info
  },
  SET_EXAM_REPORT_DATA: (state: ExamState, params: { type: ReportTypeEnum, reportData: ReportDataType }) => {
 
    // 考试报表信息
    state.reportData[params.type] = params.reportData
  },
  SET_EXAM_DETAIL_DATA: (state: ExamState, params: { type: ReportTypeEnum, reportData: ReportDataType }) => {
   
    // 考试详情报表信息
    state.reportDetail[params.type] = params.reportData
  },
  SET_EXCELL_REPORT_DATA: (state: ExamState, params: { type: ReportTypeEnum, workReportData: ReportDataType }) => {
 
    // 考试报表信息
    state.workReportData[params.type] = params.workReportData
  },
}

const actions = {
    


  // 获取考试信息，包括科目信息在内
  getExamInfo({ dispatch }: any, params: { examGuid: string, examRuCode: string, ruCode?: string }) {
    return new Promise((resolve: any, reject) => {
      examApi.getExamInfo(params).then((data: any) => {
        if (data && data.List && data.List.length) {
          return dispatch('getExamSubjects', data.List[0])
        } else {
          reject()
        }
      }).then(resolve).catch(reject)
    })
  },
  // 通过考试信息获取科目信息
  getExamSubjects({ commit }: any, examInfo:any = {}) {
    return new Promise((resolve: any, reject) => {
      examApi.getExamData({ examGuid: examInfo.ExamGuid, templateguid: examInfo.TemplateGuid, examRuCode: examInfo.ExamRuCode, type: ReportTypeEnum.KM }).then((data: any) => {
        if (data && data.length) {
          // 必须要有科目信息才能算正常
          commit('SET_EXAM_INFO', {
            ...examInfo,
            subjects: data
          })
          resolve() 
        } else {
          reject()
        }
      }, reject)
    })
  },

  // 获取作业面板数据
//   getExcellReportData({ commit, state }: any, params: any){
//     return new Promise((resolve: any, reject)=>{
//       // 考试中所有类型
//       const allTypes = [0,1]
//       const requests: Array<Promise<any>> = []
//       const examInfo = state.examInfo
//       if (examInfo) {
     
//         allTypes.forEach((type) => {
//           commit('SET_EXCELL_REPORT_DATA', { type, reportData: { loading: true, data: '' }})
//           requests.push(examApi.getExamData({ examGuid: examInfo.ExamGuid, templateguid: examInfo.TemplateGuid, examRuCode: examInfo.ExamRuCode, type, ...params }))
//         })
//         const loadRequest = (index: number):Promise<any> => {
//           if (index < requests.length) {
//             return requests[index].then((data: any) => {
        
            
//               commit('SET_EXAM_REPORT_DATA', {
//                 type: allTypes[index],
//                 reportData: { loading: false, data }
//               })
//               return loadRequest(index + 1)
//             }, () => {
//               // 请求失败了，设置 loading为 false
//               console.log('请求失败！', index)
//               commit('SET_EXAM_REPORT_DATA', {
//                 type: allTypes[index],
//                 reportData: { loading: false, data: '' }
//               })
//               return loadRequest(index + 1)
//             })
//           } else {
//             resolve()
//             return Promise.resolve()
//           }
//         }
//         loadRequest(0)
//       } else {
//         return reject()
//       }
//     })
//   },
  // 获取考试面板报表数据，params 是额外参数
  getExamReportData({ commit, state }: any, params: any) {
    return new Promise((resolve: any, reject) => {
      // 考试中所有类型
      const allTypes = [ReportTypeEnum.FourRate, ReportTypeEnum.ReachRate, ReportTypeEnum.TopNStudent, ReportTypeEnum.Top50Student, ReportTypeEnum.AcademicGrade]
      const requests: Array<Promise<any>> = []
      const examInfo = state.examInfo
      if (examInfo) {
     
        allTypes.forEach((type) => {
          commit('SET_EXAM_REPORT_DATA', { type, reportData: { loading: true, data: '' }})
          requests.push(examApi.getExamData({ examGuid: examInfo.ExamGuid, templateguid: examInfo.TemplateGuid, examRuCode: examInfo.ExamRuCode, type, ...params }))
        })
        const loadRequest = (index: number):Promise<any> => {
          if (index < requests.length) {
            return requests[index].then((data: any) => {
        
            
              commit('SET_EXAM_REPORT_DATA', {
                type: allTypes[index],
                reportData: { loading: false, data }
              })
              return loadRequest(index + 1)
            }, () => {
              // 请求失败了，设置 loading为 false
              console.log('请求失败！', index)
              commit('SET_EXAM_REPORT_DATA', {
                type: allTypes[index],
                reportData: { loading: false, data: '' }
              })
              return loadRequest(index + 1)
            })
          } else {
            resolve()
            return Promise.resolve()
          }
        }
        loadRequest(0)
      } else {
        return reject()
      }
    })
  },
  // 获取考试详情报表数据，params 是额外参数
  getExamDetailData({ commit, state }: any, params: any) {
    return new Promise((resolve: any, reject) => {
      // 考试中所有类型
      const allTypes = [ReportTypeEnum.ContributionRate, ReportTypeEnum.SubTopicScore, ReportTypeEnum.BoxplotDistributed, ReportTypeEnum.ExamBasicScore, ReportTypeEnum.TotalScoreRate, ReportTypeEnum.FourRate, ReportTypeEnum.ScoreSegment, ReportTypeEnum.CriticalDistributed]
      const requests: Array<Promise<any>> = []
      const examInfo = state.examInfo
      if (examInfo) {
        allTypes.forEach((type) => {
          commit('SET_EXAM_DETAIL_DATA', { type, reportData: { loading: true, data: '' }})
          requests.push(examApi.getExamData({ examGuid: examInfo.ExamGuid, templateguid: examInfo.TemplateGuid, examRuCode: examInfo.ExamRuCode, rank: 100, type, ...params }))
        })

        const loadRequest = (index: number):Promise<any> => {
          if (index < requests.length) {
            return requests[index].then((data: any) => {
              commit('SET_EXAM_DETAIL_DATA', {
                type: allTypes[index],
                reportData: { loading: false, data }
              })
              return loadRequest(index + 1)
            }, () => {
              // 请求失败了，设置 loading为 false
              console.log('请求失败！', index)
              commit('SET_EXAM_DETAIL_DATA', {
                type: allTypes[index],
                reportData: { loading: false, data: '' }
              })
              return loadRequest(index + 1)
            })
          } else {
            resolve()
            return Promise.resolve()
          }
        }
        loadRequest(0)
      } else {
        return reject()
      }
    })
  },
  // 获取考试详情报表数据 3个table
  getExamDetailTable({ commit, state }: any, params: any) {
    return new Promise((resolve: any, reject) => {
    // 考试中所有类型
      const allTypes = [ReportTypeEnum.FourRate, ReportTypeEnum.SubTopicAnalysis]
      const requests: Array<Promise<any>> = []
      const examInfo = state.examInfo
      if (examInfo) {
        allTypes.forEach((type) => {
          commit('SET_EXAM_DETAIL_DATA', { type, reportData: { loading: true, data: '' }})
          requests.push(examApi.getExamData({ examGuid: examInfo.ExamGuid, templateguid: examInfo.TemplateGuid, examRuCode: examInfo.ExamRuCode, type, ...params }))
        })

        const loadRequest = (index: number):Promise<any> => {
          if (index < requests.length) {
            return requests[index].then((data: any) => {
              commit('SET_EXAM_DETAIL_DATA', {
                type: allTypes[index],
                reportData: { loading: false, data }
              })
              return loadRequest(index + 1)
            }, () => {
            // 请求失败了，设置 loading为 false
              console.log('请求失败！', index)
              commit('SET_EXAM_DETAIL_DATA', {
                type: allTypes[index],
                reportData: { loading: false, data: '' }
              })
              return loadRequest(index + 1)
            })
          } else {
            resolve()
            return Promise.resolve()
          }
        }
        loadRequest(0)
      } else {
        return reject()
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
