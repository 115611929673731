
// 当前所查看的单位类型
export enum UnitTypeEnum {
  Union = '1', // 联考 
  School = '0' // 校考
}

// 报表类型
export enum ReportTypeEnum {
  // 监控面板相关
  KM = 14, // 科目数据
  FourRate = 0, // 一分四率
  ContributionRate = 1, // 贡献率
  ReachRate = 2, // 达线率
  Top50Student = 3, // 前50名学生分布接口
  AcademicGrade = 4, // 学业等级分布
  // 详情相关
  TopNStudent = 5, // 拔尖学生花名册
  SubTopicScore = 6, // 小题分得分情况
  BoxplotDistributed = 7, // 盒须图分布
  ExamBasicScore = 8, // 考试基本得分信息
  TotalScoreRate = 9, // 考试全部得分率
  ScoreSegment = 10, // 成绩分段表
  CriticalDistributed = 11, // 临界生分布
  SubTopicAnalysis = 13, // 小题分析表
}
