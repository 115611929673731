import { asyncRoutes, constantRoutes } from '@/router'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles: any, route: any) {
  if (route.meta && route.meta.roles) {
    return roles.some((role: string) => route.meta.roles.indexOf(role) >= 0)
  } else if (route.path === '*') {
    return true
  } else {
    return false
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes: any, roles: any) {
  const res: any = []
  routes.forEach((route: any) => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })
  return res
}

declare interface PermissionState {
  routes: Array<any>,
  addRoutes: Array<any>
}

const state: PermissionState = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state: PermissionState, routes: Array<any>) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }: any, userInfo: any) {
    return new Promise(resolve => {
      let accessedRoutes = []
      const roles = userInfo.currentRoles
      accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  }
}

const getters = {
  permissionRouters: (state: PermissionState) => state.routes
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
