import { configure, start, done } from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style 

import router from './router'
import store from './store'
import { getToken } from './utils/auth' // get token from cookie
import qs from 'query-string'

configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/404', '/500', '/await', '/scroll-demo'] // no redirect whitelist

router.beforeEach(async (to: any, _from: any, next) => {
  // start progress bar
  start()
  // determine whether the user has logged in
  const hasToken = getToken()
  if (whiteList.includes(to.path)) {
    // in the free login whitelist, go directly
    next()
  } else if (hasToken) { 
    const { userInfo } = store.getters.user
    if (userInfo) {
      const routes = router.getRoutes().filter((r) => r.path === to.path)
      if (routes.length) {
        next()
      } else {
        // illegal router
        next('/404')
      }
    } else {
      // go await page to get userInfo
      const query: any = { redirect: encodeURIComponent(to.path) }
      qs.stringify(to.query) && (query.query = encodeURIComponent(qs.stringify(to.query)))
      next({ path: '/await', replace: true, query: query })
    }
  } else {
    // other pages that do not have permission to access are redirected to the login page.
    next('/login')
  }
})

router.afterEach(() => {
  // finish progress bar
  done()
})
