import request from '@/utils/request'
import { ref } from 'vue'
function $get(url: string, params?: any) {
  return request({
    url: url,
    method: 'get',
    params: params
  })
}

function $post(url: string, params?: any, isQuery?: boolean) {
  const body: any = {
    url: url,
    method: 'post'
  }
  body[isQuery ? 'params' : 'data'] = params || {}
  return request(body)
}

function $formdata(url: string, data: any) {
  const form = new FormData()
  for (const key in data) {
    if (key.indexOf('file') > -1 && Array.isArray(data[key])) {
      data[key].forEach((item: any) => {
        form.append(key, item)
      })
    } else {
      form.append(key, data[key])
    }
  }
  return $post(url, form)
}

/**
 * 声明了一个全局的挂载在window上的响应式变量 windowActived
 * 用于监听窗口状态变化
 */
window.windowActived = ref(true)

window.onfocus = function () {
  window.windowActived.value = true
}

window.onblur = function () {
  window.windowActived.value = false
}

export default { $get, $post, $formdata }
