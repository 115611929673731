
// interface UserState {
//     data: any 
// }
// import persist from 'vuex-persistedstate'
const getDefaultState = () => {
  const initState = {
      data: [],
      units:''

  }
  return initState
}

const state = getDefaultState()

const mutations = {
    handleChangeMethod:(state:any,val:any)=>{
        state.data = val
    },
    handleUnits:(state:any,val:any)=>{
      state.units = val
    }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

