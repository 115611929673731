<template>
  <div class="screen-adapter">
    <div class="main-container" :style="style">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
// 当前适配器会相对于设计图（传入宽高）尺寸进行缩放。内部元素px完全按照设计图尺寸写即可
import { defineComponent, onMounted, onUnmounted, reactive } from 'vue'
import _ from 'lodash'
export default defineComponent({
  name: 'ScreenAdapter',
  props: {
    w: { // 设计图尺寸宽
      type: Number,
      default: 1920
    },
    h: { // 设计图尺寸高
      type: Number,
      default: 1080
    }
  },
  setup(props) {
    const style = reactive({
      width: props.w + 'px',
      height: props.h + 'px',
      transform: 'scale(1) translate(-50%, -50%)' // 默认不缩放，垂直水平居中
    })

    // 截流处理
    const handleResize = _.throttle(function () {
      setScale()
    }, 300)

    // 获取缩放倍数
    function getScale() {
      const w = window.innerWidth / props.w
      const h = window.innerHeight / props.h
      return w < h ? w : h
    }

    // 设置缩放参数
    function setScale() {
      style.transform = `scale(${getScale()}) translate(-50%, -50%)`
    }

    onMounted(() => {
      setScale()
      window.addEventListener('resize', handleResize)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })

    return {
      style
    }
  }
})
</script>

<style lang="scss" scoped>
.screen-adapter {
  max-width: 100vw;
  min-width:100%;
  min-height: 100%;
  max-height: 100vh;
  overflow: hidden;
  background-color:  #061123;

  .main-container {
    transform-origin: 0 0;
    transition: all .3s linear;
    position: absolute;
    top: 50%;
    left: 50%;
    color: $blueLine;
    background-color: #061123;
  }
}
</style>
