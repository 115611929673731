import { createApp } from 'vue'

import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElIconModules from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import './styles/index.scss' // global css
import 'animate.css'

import App from './App.vue'
import router from './router'
import store from './store'
import './permission' // permission control

// global components
import SvgIcon from './components/SvgIcon/index.vue'
import Loading from '@/components/Loading/index.vue'

const app = createApp(App)

import NP from 'number-precision'
NP.enableBoundaryChecking(false)

// global methods
import global from './utils/global'
for (const variable in global) {
  app.config.globalProperties[variable] = (global as any)[variable]
}

// global directives
import * as directives from './directives'
Object.keys(directives).forEach(key => {
  app.directive(key, directives[key])
})

app.config.errorHandler = function (err: any) {
  console.error(err)
}

app.use(router)
  .use(store)
  .use(ElementPlus, {
    locale: zhCn
  })
  .component('svg-icon', SvgIcon)
  .component('loading', Loading)
  .mount('#app')

Object.keys(ElIconModules).forEach(function (key) { 
  const Icon = ElIconModules[key]
  app.component(Icon.name, Icon)
})

export default app
