<template>
  <div v-if="realLoading || empty" class="loading-container">
    <svg-icon v-if="realLoading" name="await-loading"></svg-icon>
    <template v-else-if="$slots.empty">
      <slot name="empty"></slot>
    </template>
    <template v-else>
      <el-empty :image="isCharts ? EmptyChartsImage : EmptyDataImage" :description="emptyText"></el-empty>
    </template>
  </div>
  <template v-else>
    <slot></slot>
  </template> 
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import EmptyChartsImage from '@/assets/images/empty_charts.png'
import EmptyDataImage from '@/assets/images/empty_data.png'

const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
    default: () => false
  },
  empty: {
    type: Boolean,
    required: false,
    default: () => false
  },
  isCharts: {
    type: Boolean,
    required: false,
    default: () => false
  },
  emptyText: {
    type: String,
    required: false,
    default: () => '暂无数据'
  }
})

const realLoading = ref(false)

watch(() => props.loading, (newValue) => {
  if (newValue) {
    realLoading.value = newValue
  } else {
    // 关闭的时候做了 300ms延迟，不然太快了不好看
    setTimeout(() => {
      realLoading.value = false
    }, 300)
  }
}, { immediate: true })

</script>

<style lang="scss" scoped>
.loading-container {
  width: 100%;
  height: 100%;
  display: flex;

  .svg-icon {
    width: 50px;
    height: 50px;
    margin: auto;
  }

  ::v-deep(.el-empty) {
    margin: auto;

    .el-empty__image {
      width: 80%;
    }

    .el-empty__description p {
      font-size: 14px;
      color: $whiteText;
    }
  }
}
</style>
