import axios from 'axios'
import { start, done } from 'nprogress' // progress bar
import { serviceMessage } from './message'
// import { removeEmptyField } from '@/utils'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API,
  timeout: 60000,
  withCredentials: true, // send cookies when cross-domain requests
  
})
// timeout `0` (no timeout)
service.defaults.timeout = 0

// 发起请求之前的拦截器
service.interceptors.request.use(
  (config: any) => {
    start()
    const token = getToken()
    // 设置 Token，注意空格不能去
    config.headers['Authorization'] = 'Bearer ' + token
    if (config.method === 'get') {
      config.params = {
        ...config.params,
        _v: new Date().getTime() // Get 请求加个时间戳
      }
    }
   
    return config
  },
  (error: any) => Promise.reject(error)
)

// 响应拦截器
service.interceptors.response.use(
  (response: any) => {
    done()
    // if(response.data.code >= '200' && response.data.code <= '300'){ 
    //   return serviceMessage(response)
    // }
    return serviceMessage(response)


  },
  (error: any) => {
    return serviceMessage({ error })
  }
)

export default service
