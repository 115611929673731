import _ from 'lodash'
import dayjs from 'dayjs'

/**
  * date 转字符串
  * @param {Date} date 日期格式
  * @param {String} fmt 格式化样式
  * @return {String} 实际日期
  */
export function formatDate(date: Date, fmt: string) {
  date = date || new Date()
  if (((date instanceof String) || (typeof date).toLowerCase() === 'string')) date = new Date(date)
  fmt = fmt || 'yyyy-MM-dd'
  var o: any = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    'S': date.getMilliseconds() // 毫秒
  }

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }

  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}

/**
 * 友盟埋点推送
 * @param params [ '一级类别', '二级操作', '三级标签']
 * @param trackType 可选、默认 _trackEvent
 */
 export function trackPush(params: Array<any>, trackType: string = '_trackEvent') {
  if (process.env.NODE_ENV === 'development' && trackType === '_trackEvent') console.log('埋点捕获', params)
  try {
    if (window._czc && window.location.href.indexOf('7net.cc') !== -1) window._czc.push([trackType, ...params])
  } catch (error) { console.error(error) }
}

/**
 * See：https://github.com/nuxt/vite/issues/71  or https://github.com/vitejs/vite/issues/3283
 * 由于 Vite 框架在使用 import variables from '@/styles/variables.scss' 时会报错 the file is already being loaded，暂无好的解决办法
 * 在 vite.config.js中设置 additionalData 内容，保证能够导出String内容不报错，但要经过正转换才可以正常使用。
 * 故这里特殊处理下，方便使用
 *
 * @returns { red: '#FF000', menuText: '#909399', ... }
 */
import variables from '@/styles/variables.scss'
export function styleVariables() {
  try {
    const regExp = '(\\S+)\\s*:\\s*(\\S+)' // 通过正则匹配符合 `a:b` 的结构
    const resArr: any = variables.match(new RegExp(regExp, 'g'))
    const resStr = (resArr && resArr.length) ? resArr.join('').replace(/\s+/g, '').replace(/:/g, ':\'').replace(/;/g, '\',') : ''
    return new Function('return {' + resStr + '}')()
  } catch (error) {
    return {}
  }
}

// 判断值是否为空
export function isNull(value: any) {
  return !value && value !== 0 && typeof value !== 'boolean'
}

// 是否是数字
export function isNumber(value: any) {
  return typeof value === 'number' && !isNaN(value)
}

// 给某个element添加一个动画效果, 参考 https://github.com/daneden/animate.css
export function animateCSS(element: any, animationName: string, callback: Function) {
  const node = document.querySelector(element)
  node.classList.add('animated', animationName)
  function handleAnimationEnd() {
    node.classList.remove('animated', animationName)
    node.removeEventListener('animationend', handleAnimationEnd)
    if (typeof callback === 'function') callback()
  }
  node.addEventListener('animationend', handleAnimationEnd)
}

// 去除对象中字段为空的值
export const removeEmptyField = (obj: any) => {
  const params: any = {}
  if (Array.isArray(obj)) {
    return obj
  }
  for (const key in obj) {
    obj[key] === undefined || obj[key] === '' || (params[key] = obj[key])
  }
  return params
}

/**
 * // 获取一个时间范围
 * @param type 范围类型，
 * @param format 输出的格式化
 * @returns { examStartTime: string, examEndTime: string }
 */
export function getTimeRange(type: '一个月内'|'半年内'|'一年内' = '一年内', format: string = 'YYYY-MM-DD') {
  const now = dayjs()
  let examStartTime = ''
  let examEndTime = ''
  switch (type) {
    case '一个月内':
    {
      examStartTime = now.add(-1, 'M').format(format)
      examEndTime = now.format(format)
      break
    }
    case '半年内':
    {
      examStartTime = now.add(-6, 'M').format(format)
      examEndTime = now.format(format)
      break
    }
    default:
    {
      // 一年内
      examStartTime = now.add(-1, 'y').format(format)
      examEndTime = now.format(format)
      break
    }
  }
  return {
    examStartTime,
    examEndTime
  }
}
