<template>
  <ScreenAdapter :w="1920" :h="1080">
    <router-view />
  </ScreenAdapter>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ScreenAdapter from '@/components/ScreenAdapter/index.vue'
export default defineComponent({
  components: { ScreenAdapter }
})
</script>
