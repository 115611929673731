/**
 * v-table-auto-scroll 具体实现
 *
 * 描述：用于表格内容的 自动 or 控制 轮巡滚动
 * 用法：
 * 1、v-table-auto-scroll
 * 1、v-table-auto-scroll="autoScroll"  autoScroll = boolean
 * 2、v-table-auto-scroll="autoScrollConfig" autoScrollConfig = { auto, loop, minScrollHeight, speed }
 *
 */

import { shallowReactive, watch } from 'vue'
import { isNull, isNumber } from '@/utils'
import NP from 'number-precision'
import _ from 'lodash'

/**
 * 静态配置
 * ThrottleConfig：截流配置，只处理最后一次
 * InstanceKey： 保存在 el dom 上的指令实例 Key
 * TimeInterval：每次滚动刷新的时间间隔
 */
const ThrottleConfig: { leading: boolean, trailing: boolean } = { leading: false, trailing: true }
const InstanceKey = 'ElTableAutoScrollInstance'
const TimeInterval = 80

/**
 * ControllerType：控制器参数类型
 * 带“*”表示可通过外界参数设置
 */
type ControllerType = {
  auto: boolean // *是否自动滚动
  loop: boolean // *是否轮巡
  speed: number // *滚动速度
  minScrollHeight: number // *最小开始滚动高度，滚动高度小于此值不予滚动
  startScrolling: boolean // 是否开始滚动
  scrollHeight: number // 待滚动高度
  scrollTop: number // 当前滚动高度
  tableScrollBarRef: any // el-table滚动组建实例
}

type BindValueType = boolean|ControllerType

class AutoScrollInstance {
  private controller: ControllerType // 控制器
  private scrollTimer: any = null // 滚动循环的定时器
  private throttleUpdate: (bindValue: BindValueType, vnode: any) => void // 用于更新控制器的函数
  private watcherArray: Array<any> = [] // 监视器列表

  constructor(el: HTMLElement, bindValue: BindValueType, vnode: any) {
    this.controller = shallowReactive({
      auto: true,
      loop: true,
      startScrolling: false,
      minScrollHeight: 40,
      scrollHeight: 0,
      scrollTop: 0,
      speed: 1,
      tableScrollBarRef: null
    })
    this.initMouseListener(el) // 监听鼠标移入移出事件
    this.initController(bindValue, vnode) // 初始化控制器参数
    this.readyToScroll() // 监听值变化，准备开始滚动
    // update 会有多次回调故这里做截流处理，300ms 只处理最后一次
    this.throttleUpdate = _.throttle(this.initController, 500, ThrottleConfig)
  }

  // 对外公开函数
  public update(bindValue: BindValueType, vnode: any) {
    // 更新前先关闭当前滚动
    this.beforeSetStartScrolling(false)
    this.throttleUpdate(bindValue, vnode)
  }

  /**
   * 初始化控制器参数
   * @param vnode vnode
   * @param value binding.value
   */
  private initController(bindValue: BindValueType, vnode: any) {
    // 防错处理
    try {
      // 初始化控制器参数 判定类型多样性
      switch (typeof bindValue) {
        case 'boolean':
          this.controller.auto = bindValue
          break
        case 'object':
        {
          // 配置合法的默认参数
          const { auto, loop, minScrollHeight, speed }: ControllerType = bindValue
          if (!isNull(auto)) this.controller.auto = Boolean(auto)
          if (!isNull(loop)) this.controller.loop = Boolean(loop)
          if (isNumber(minScrollHeight)) this.controller.minScrollHeight = Number(minScrollHeight)
          if (isNumber(speed)) this.controller.speed = Number(speed)
          break
        }
        default:
          break
      }
      // element-plus el-table负责滚动的组件，保存一份即可
      if (!this.controller.tableScrollBarRef) this.controller.tableScrollBarRef = vnode.ref.i.refs.scrollBarRef
      // 设置可以滚动的高度
      const { bodyScrollHeight, bodyHeight } = vnode.ref.i.layout
      this.controller.scrollHeight = bodyScrollHeight.value - bodyHeight.value
      // 可以开始滚动
      this.beforeSetStartScrolling(true)
    } catch (error) {
      console.error(error)
      // 报错了停止滚动
      this.beforeSetStartScrolling(false)
    }
  }

  /**
   * 准备滚动，监听 startScrolling 和 scrollTop，
   * startScrolling 值的变化去调用 handleScroll
   * scrollTop 值的变化用于设置表格滚动距离 setScrollTop
   */
  private readyToScroll() {
    try {
      // 监听 startScrolling 判断滚动是否开始，并传递至滚动事件去处理
      let startTimer: any = null
      const watchS = watch(() => this.controller.startScrolling, (newValue) => {
        if (this.scrollTimer) {
          clearTimeout(this.scrollTimer)
          this.scrollTimer = null
        }
        if (newValue) {
          if (startTimer) {
            clearTimeout(startTimer)
            startTimer = null
          }
          startTimer = setTimeout(this.handleScroll.bind(this, NP.plus(this.controller.scrollTop, this.controller.speed)))
        }
      }, { immediate: true })

      // 监听 scrollTop 并设置表格实际滚动高度 setScrollTop
      const watchT = watch(() => this.controller.scrollTop, (newValue) => {
        try {
          if (this.controller.tableScrollBarRef) this.controller.tableScrollBarRef.setScrollTop(newValue)
        } catch (error) { /* eslint-disable-next-line */ }
      }, { immediate: true })

      /**
       * window 会存在一个 windowActived 自定义的响应式字段，在 utils/global.ts 中声明的
       * 获取当前视窗是否正在操作，若离开当前视窗则可以停止滚动事件
       * 监听视窗是否正在响应中，无响应了关闭滚动
       */
      const watchW = watch(window.windowActived, (newValue: boolean) => {
        this.beforeSetStartScrolling(newValue)
      })

      // 先清空之前的监听器，再添加
      this.clearWatcher()
      this.watcherArray.push(watchS, watchT, watchW)
    } catch (error) {
      console.error(error)
      // 报错了停止滚动
      this.beforeSetStartScrolling(false)
    }
  }

  /**
    * 实际的滚动事件处理方法
    * 若 startScrolling = true 则一只自调，并设置 scrollTop 值
    * 若 startScrolling = false 则停止自调
    */
  private handleScroll(scrollTop: number) {
    // 若等待滚动开启，并且可以自动滚动
    if (this.scrollTimer) {
      clearTimeout(this.scrollTimer)
      this.scrollTimer = null
    }
    if (this.controller.startScrolling) {
      const dif = NP.minus(this.controller.scrollHeight, scrollTop)
      let nextTop = 0
      if (dif > 0) {
        // 还没滚完
        nextTop = NP.plus(scrollTop, this.controller.speed)
      } else if (dif < 0) {
        // 滚过了
        nextTop = this.controller.scrollHeight
      } else {
        // 滚完了，判断是否开始轮巡，不轮巡直接结束并停止滚动
        if (!this.controller.loop) return this.beforeSetStartScrolling(false)
      }
      this.controller.scrollTop = scrollTop
      this.scrollTimer = setTimeout(this.handleScroll.bind(this, nextTop), TimeInterval)
    }
  }

  /**
   * 这里是处理设置 startScrolling 值的地方，所有设置 startScrolling 的地方都应该走此方法
   * 在设置为true的时候，这里会判断 auto 和当前滚动高度是否支持设置为true
   * @param readyStart 是否开始滚动
   */
  private beforeSetStartScrolling(readyStart: boolean) {
    // 相同的值不需要走
    if (this.controller.startScrolling === readyStart) return
    // 若 readyStart 为 true ，需要额外判断 auto && scrollHeight > minScrollHeight，才能真正开始滚动，否则不予滚动
    readyStart = readyStart && this.controller.auto && this.controller.scrollHeight > this.controller.minScrollHeight
    // 若开始滚动，则获取下当前滚动条的高度并从当前滚动高度开始滚动
    if (readyStart && this.controller.tableScrollBarRef) this.controller.scrollTop = this.controller.tableScrollBarRef.wrap$.scrollTop

    this.controller.startScrolling = readyStart
  }

  /**
   * 初始化鼠标事件监听
   * @param el Dom
   * @param isRemove 是否移除事件
   */
  private initMouseListener(el: HTMLElement, isRemove?: boolean) {
    el.onmouseenter = isRemove ? null : () => {
      // 鼠标移入，默认关闭滚动
      this.beforeSetStartScrolling(false)
    }
    el.onmouseleave = isRemove ? null : () => {
      // 鼠标移出，默认打开滚动
      if (window.windowActived.value) this.beforeSetStartScrolling(true)
    }
    el.onmouseover = isRemove ? null : _.throttle(() => {
      // 用于防止页面刷新时鼠标停留在dom内部导致无法监听到 onmouseenter
      this.beforeSetStartScrolling(false)
    }, 500, { leading: true, trailing: false })
  }

  /**
   * 释放所有监视器
   */
  private clearWatcher() {
    while (this.watcherArray.length) {
      const watcher = this.watcherArray.shift()
      if (typeof watcher === 'function') watcher()
    }
  }

  /**
   * 对外公开函数，销毁滚动事件
   * @param el Dom
   */
  public destory(el: HTMLElement) {
    this.clearWatcher()
    this.initMouseListener(el, true)
    this.beforeSetStartScrolling(false)
    // el-table 滚动组件实例要释放掉
    this.controller.tableScrollBarRef = null
  }
}

export const TableAutoScroll = {
  /**
   * Vue 指令钩子函数
   * @param el Dom
   * @param binding 绑定的值
   */
  mounted(el: HTMLElement, binding: any, vnode: any) {
    // 组件加载完成时绑定滚动实例到 el 上。
    if (!el[InstanceKey]) {
      const instance = new AutoScrollInstance(el, binding.value, vnode)
      el[InstanceKey] = instance
    }
  },
  updated(el: HTMLElement, binding: any, vnode: any) {
    if (!el[InstanceKey]) return
    el[InstanceKey].update(binding.value, vnode)
  },
  beforeUnmount(el: HTMLElement) {
    if (!el[InstanceKey]) return
    // 销毁实例
    el[InstanceKey].destory(el)
    // 实例置空
    el[InstanceKey] = null
  }
}
