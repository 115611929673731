import request from '@/utils/request'
export default {
/**
 *  获取科目信息
 */
  getHomeWorkSubject(data: {}) {
    return request({
      url: '/ras/homework/params',
      method: 'post',
      data
    })
  },
  /**
   * 获取教育局/学校平均得分率
   * @param data
   * @returns
   */
  getHwAvgScoreRate(data: {gradeCode:string, km:string, queryMonth:string, eduId: string, rucode:string}) {
    return request({
      url: '/ras/homework/getHwAvgScoreRate',
      method: 'post',
      data
    })
  },
  /**
   * 获取教育局/学校批阅率
   * @param data
   * @returns
   */
  getHwMarkRate(data: {gradeCode:string, km:string, queryMonth:string, eduId: string, rucode:string}) {
    return request({
      url: '/ras/homework/getHwMarkRate',
      method: 'post',
      data
    })
  },
  /**
   * 获取教育局/学校作业时长和试题量
   */
  getHwDayDurationAndCount(data: {gradeCode:string, km:string, queryDate:string, eduId: string, rucode:string}) {
    return request({
      url: '/ras/homework/getHwDayDurationAndCount',
      method: 'post',
      data
    })
  },
  getDifficulty(data: {gradeCode:string, km:string, queryMonth:string, eduId: string, rucode:string}) {
    return request({
      url: '/ras/homework/getDifficulty',
      method: 'post',
      data
    })
  },
  getItemCount(data: { km:string, eduId: string, rucode:string}) {
    return request({
      url: '/ras/homework/getItemCount',
      method: 'post',
      data
    })
  },
  getKnowledge(data: {gradeCode:string, km:string, queryMonth:string, eduId: string, rucode:string}) {
    return request({
      url: '/ras/homework/getKnowledge',
      method: 'post',
      data
    })
  }
}
