import request from '@/utils/request'

/**
 * 登录前
 * @param {*} data
 * @returns
 */
export function allowLogin(data: { telephone: string }) {
  return request({
    url: '/ras/sysUser/allowLogin',
    method: 'post',
    data
  })
}

/**
 * 登录
 * @param {*} data
 * @returns
 */
export function login(data: { username: string, password: string, inValid?: 'protal' ,who:string}) {
  return request({
    url: '/auth/login',
    method: 'post',
    data
  })
}

export function getUserInfo() {
  return request({
    url: '/ras/userOrg/userInfo',
    method: 'post'
  })
}

export function logout() {
  return new Promise((res: any) => {
    res({
      status: 200,
      data: {}
    })
  })
}
