import request from '@/utils/request'
import { ReportTypeEnum } from '@/types/enums'
export default {

  /**
   * 获取考试信息
   */
  getExamInfo(data: { examGuid: string, examRuCode: string, ruCode?: string }) {
    return request({
      url: '/ras/exam/examDetail',
      method: 'post',
      data
    })
  },

  /**
   * 考试报表，type 标识考试内容，例如 0：一分四率，1：贡献率， 2：达线率，
   * 3：前N名学生分布接口，4：学业等级分布接口
   * 14：科目 等， 参考 ReportTypeEnum
   */
  getExamData(data: { type: ReportTypeEnum, examRuCode: string, examGuid: string, templateguid: string }) {
    return request({
      url: '/ras/exam/examData',
      method: 'post',
      data 
    })
  },

  /**
   * 考试列表
   */
  getExamList(data: {
     examGuids?: Array<string>, isAll: number,
     isUnion: Boolean, ruCode: string, ruCodes: Array<string>,
     examName?: string, pageIndex: number, pageSize: number,
     examStartTime: string, examEndTime: string
    }) {
    return request({
      url: '/ras/exam/list',
      method: 'post',
      data
    })
  },

  /**
   * 联考数和校考数
   */
  getExamCount(data: { edu_id: string, examStartTime: string, examEndTime: string, ruCode: string }) {
    return request({
      url: '/ras/exam/unionAndSchoolExamCount',
      method: 'post',
      data
    })
  },

  /**
   * 获取教育局下学校列表
   */
  getSchoolList(data: { orgId: string, period: string, pageIndex: number, pageSize: number }) {
    return request({
      url: '/ras/eduSchool/getPeriodSchool',
      method: 'post',
      data
    })
  },

  /**
   * 获取扩展应用下的学生素养数据
   */
  getExtendedData(data: {
    type: string|number, isUnion: Boolean, schoolGuid: string, units?: Array<string>,
    km:string, templateguid: string, examGuid: string, examRuCode: string,
    pageIndex: number, pageSize: number, searchWord: string
  }) {
    return request({
      url: '/ras/exam/extendedData',
      method: 'post',
      data
    })
  },

  /**
   * 根据学校ruCode获取学校的行政班数和教师数
   */
  getSchoolClassTeacherCount(data: { rucode: string }) {
    return request({
      url: '/ras/exam/getSchoolClassCountAndTeacherCount',
      method: 'post',
      data
    })
  }

}

