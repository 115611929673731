import { ElMessage, ElMessageBox } from 'element-plus'
import store from '@/store'
import router from '@/router'

// 重写 Message 提示
let messageInstance: any = null
const resetMessage: any = (options: any) => {
  if (options.only) {
    if (document.getElementsByClassName('el-message').length === 0) {
      messageInstance = ElMessage(options)
    } else {
      if (messageInstance) {
        messageInstance.close()
      }
      messageInstance = ElMessage(options)
    }
  } else {
    if (messageInstance) {
      messageInstance.close()
    }
    messageInstance = ElMessage(options)
  }
}
['error', 'success', 'info', 'warning'].forEach((type: string) => {
  resetMessage[type] = (options: any) => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
    }
    options.type = type
    return resetMessage(options)
  }
})

export const MessageToast = resetMessage
export const MessageBox = ElMessageBox

// 服务接口返回错误提示
export const serviceMessage = (response: any) => {
  if (!response.error) {
    // 成功回调
    const res = response.data
    switch (res.code) {
      case 200:
        return res.data || res
      case 302:
        store.dispatch('user/removeToken').then(() => {
          router.replace('/login')
        })
        MessageToast.error(res.msg)
        break
      default:
        MessageToast.error(res.msg)
        break
    }
    const error = '\nurl: => ' + response.config.url +
            '\ndata: => ' + (response.config.data || response.config.params) +
            '\ncode: => ' + res.code +
            '\nmessage: => ' + res.msg
    console.error(error)
    return Promise.reject(res)
  } else {
    const error = response.error
    // 错误回调
    if (error.message.includes('timeout')) {
      MessageToast({
        message: '网络超时，请稍后再试',
        type: 'error',
        duration: 3 * 1000
      })
    } else {
      MessageToast({
        message: '网络异常，请稍后再试',
        type: 'error',
        duration: 3 * 1000
      })
    }
    return Promise.reject(error)
  }
}

/**
 * 用于跳转其他站点，写在异步方法中（如接口请求完成）会导致跳转失败，故特殊处理
 * @param {*} url 站点地址
 * @param {*} isAsync 是否在异步方法中
 */
export const openNewWindow = (url: string, isAsync?: boolean) => {
  if (!isAsync) {
    window.open(url, '_blank')
  } else {
    ElMessageBox({
      title: '跳转提示',
      message: '此操作将离开此页面, 是否继续？',
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      window.open(url, '_blank')
    }).catch(() => { })
  }
}
