import Cookies from 'js-cookie'

let hostname: any = window.location.hostname
// 本地开发环境取代理域名
if (process.env.NODE_ENV === 'development') {
  const proxy: any = import.meta.env.VITE_APP_PROXY_API
  hostname = proxy.split('/')[2]
}

type CookieKeyType = 'yjticket'|'7netticket'

// 测试：yjticket   线上、灰度：7netticket
const CookieKey: CookieKeyType = !~hostname.indexOf('7net.cc') ? 'yjticket' : '7netticket'

// 获取 token
export function getToken() {
  return Cookies.get(CookieKey)
}

// 移除 token
export function removeToken() {
  Cookies.remove(CookieKey, { domain: getCookieDomain(CookieKey) })
  // 这样写 * 是因为有时候会有完整域名的cookie移除不了。
  Cookies.remove(CookieKey, { path: '*' })
}

// 设置 token
export function setToken(token: string) {
  Cookies.set(CookieKey, token, { domain: getCookieDomain(CookieKey) })
}
 
// 根据环境获取对应存储域名
function getCookieDomain(env: CookieKeyType): string {
  // 本地运行直接返回 localhost
  return process.env.NODE_ENV === 'development' ? 'localhost' : (env === 'yjticket' ? '.septnet.cn' : '.7net.cc')
}
