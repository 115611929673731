
const SidebarStatus = 'DATING_ADMIN_SIDEBARSTATUS'

declare interface SidebarState {
  sidebar: {
    opened: boolean,
    withoutAnimation: boolean
  },
  device: string
}

export enum DeviceType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile'
}

const state: SidebarState = {
  sidebar: {
    opened: Boolean(Number(window.sessionStorage.getItem(SidebarStatus))),
    withoutAnimation: false
  },
  device: DeviceType.DESKTOP
}

const mutations = {
  TOGGLE_SIDEBAR: (state: SidebarState) => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      window.sessionStorage.setItem(SidebarStatus, '1')
    } else {
      window.sessionStorage.setItem(SidebarStatus, '0')
    }
  },
  CLOSE_SIDEBAR: (state: SidebarState, withoutAnimation: boolean) => {
    window.sessionStorage.setItem(SidebarStatus, '0')
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state: SidebarState, device: string) => {
    state.device = device
  }
}

const actions = {
  toggleSideBar({ commit }: any) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }: any, { withoutAnimation }: any) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }: any, device: DeviceType) {
    commit('TOGGLE_DEVICE', device)
  }
}

const getters = {
  sidebar: (state: SidebarState) => state.sidebar,
  device: (state: SidebarState) => state.device
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
