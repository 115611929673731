import _ from 'lodash'
import homeWorkApi from '@/api/homeWorkApi'
type homeworkDataType = {
  loading: false,
  data: any
}
type homeType ={
  km: Array<any>,
  grade: Array<any>
}
interface homeWorkState {
  homeInfo: homeType
  reportData: { // 作业数据
    hwAvgScoreRate: homeworkDataType // 教育局/学校平均得分率
    hwMarkRate: homeworkDataType // 教育局/学校批阅率
    hwDayDurationAndCount: homeworkDataType // 教育局/学校作业时长和试题量
    hwDifficulty: homeworkDataType // 教育局/学校题型难度
    hwItemCount: homeworkDataType // 教育局或者学校下的校本试题总量和教师上传量
    hwKnowledge: homeworkDataType // 教育局/学校知识点掌握
  }
}

const getDefaultState = () => {
  const initState: homeWorkState = {
    homeInfo: { km: [], grade: [] },
    reportData: {
      hwAvgScoreRate: { loading: false, data: '' },
      hwMarkRate: { loading: false, data: '' },
      hwDayDurationAndCount: { loading: false, data: '' },
      hwDifficulty: { loading: false, data: '' },
      hwItemCount: { loading: false, data: '' },
      hwKnowledge: { loading: false, data: '' }
    }
  }
  return initState
}

const state: homeWorkState = getDefaultState()

const mutations = {
  RESET_STATE: (state: homeWorkState) => {
    state = _.extend(state, getDefaultState())
  },
  SET_HOME_INFO: (state: homeWorkState, info: any) => {
    state.homeInfo = info
  },
  SET_HOME_REPORT_DATA: (state: homeWorkState, params: { type: any, reportData: homeworkDataType }) => {
    // 考试报表信息
    state.reportData[params.type] = params.reportData
  }
}

const actions = {
  // 获取科目年级信息
  getKmGrade({ commit }: any) {
    return new Promise((resolve: any, reject) => {
      homeWorkApi.getHomeWorkSubject({}).then((data: any) => {
        if (data && data.km && data.km.length && data.grade && data.grade.length) {
          // 必须要有科目信息才能算正常
          commit('SET_HOME_INFO', data)
          resolve(data)
        } else {
          reject()
        }
      }, reject)
    }).catch(() => {})
  },
  getHomeDetailData({ commit, state }: any, params: any) {
    return new Promise((resolve: any, reject) => {
      // 作业中所有类型
      const allTypes = ['hwAvgScoreRate', 'hwMarkRate', 'hwDifficulty', 'hwItemCount', 'hwKnowledge']
      const requests: Array<Promise<any>> = []
      const homeInfo = state.homeInfo
      if (homeInfo && homeInfo.km && homeInfo.km.length && homeInfo.grade && homeInfo.grade.length) {
        allTypes.forEach((type) => {
          commit('SET_HOME_REPORT_DATA', { type, reportData: { loading: true, data: '' }})
        })
        const { gradeCode, km, queryMonth, eduId, rucode } = params
        // const queryData = { gradeCode, km, queryDate, eduId, rucode }
        const queryData1 = { km, eduId, rucode, gradeCode }
        const queryData2 = { gradeCode, km, queryMonth, eduId, rucode }
        requests.push(homeWorkApi.getHwAvgScoreRate(queryData2))
        requests.push(homeWorkApi.getHwMarkRate(queryData2))
        //  requests.push(homeWorkApi.getHwDayDurationAndCount(queryData))
        requests.push(homeWorkApi.getDifficulty(queryData2))
        requests.push(homeWorkApi.getItemCount(queryData1))
        requests.push(homeWorkApi.getKnowledge(queryData2))

        const loadRequest = (index: number):Promise<any> => {
          if (index < requests.length) {
            return requests[index].then((data: any) => {
              commit('SET_HOME_REPORT_DATA', {
                type: allTypes[index],
                reportData: { loading: false, data }
              })
              return loadRequest(index + 1)
            }, () => {
              // 请求失败了，设置 loading为 false
              console.log('请求失败！', index)
              commit('SET_HOME_REPORT_DATA', {
                type: allTypes[index],
                reportData: { loading: false, data: '' }
              })
              return loadRequest(index + 1)
            })
          } else {
            resolve()
            return Promise.resolve()
          }
        }
        loadRequest(0)
      } else {
        return reject()
      }
    }).catch(() => {})
  },
  getDayDurationAndCount({ commit, state }: any, params: any) {
    return new Promise((resolve: any, reject) => {
      const homeInfo = state.homeInfo
      if (homeInfo && homeInfo.km && homeInfo.km.length && homeInfo.grade && homeInfo.grade.length) {
        commit('SET_HOME_REPORT_DATA', { type: 'hwDayDurationAndCount', reportData: { loading: true, data: '' }})
        const { gradeCode, km, eduId, rucode, queryDate } = params
        const queryData = { gradeCode, km, queryDate, eduId, rucode }
        homeWorkApi.getHwDayDurationAndCount(queryData).then((data:any) => {
          commit('SET_HOME_REPORT_DATA', {
            type: 'hwDayDurationAndCount',
            reportData: { loading: false, data }
          })
          resolve()
        }, () => {
        // 请求失败了，设置 loading为 false
          commit('SET_HOME_REPORT_DATA', {
            type: 'hwDayDurationAndCount',
            reportData: { loading: false, data: '' }
          })
          reject()
        })
      } else {
        return reject()
      }
    }).catch(() => {})
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
